import React from "react";
export default function GrenzAspekteSection(props) {
    if (props) {
        return (
            <section>
                <h2 className="mb-4 has-background-light is-size-6 has-text-weight-semibold has-text-centered mt-4">Angaben zu grenzüberschreitenden Aspekten</h2>
                <div className="pl-5">
                    {props.interdisziplinaritaet == "1" ? <p>Der Artikel macht Aussagen zur Interdisziplinarität.</p> : <></>}
                    {props.intermedialitaet == "1" ? <p>Der Artikel macht Aussagen zur Intermedialitaet</p> : <></>}
                    {props.internationalitaet == "1" ? <p>Der Artikel macht Aussagen zur Internationalität</p> : <></>}
                    {props.kulturtransfer == "1" ? <p>Der Artikel mach Aussagen zum Kulturtransfer.</p> : <></>}
                    <p className="pt-4"></p>
                    {props.importAus ? <p><b>Import aus:</b> {props.importAus}</p> : <></>}
                    {props.exportNach ? <p><b>Export nach:</b> {props.exportNach}</p> : <></>}
                    <p className="pt-4"></p>
                    {props.fremdsprachigeWorte ? <p><b>Fremdsprachige Wörter: </b>{props.fremdsprachigeWorte}</p> : <></>}
                    <p className="pt-4"></p>
                </div>
            </section>
        )
    }
    else {
        return (<></>)
    }
}