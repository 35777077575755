import React, { useEffect, useState } from "react"
import Map from "./Map"

const Query = (props) => {

    const [mapData, setMapData] = useState([]);
    const [fullData, setFullData] = useState([]);
    /*const [param, setParam] = useState(props.param)*/

    let land = "";
    let thema = "";
    let autor = "";
    let zeit = "";
    let suchbegriff = "";
    if (props.land) { land = props.land }
    if (props.zeit) { zeit = props.zeit }
    if (props.thema) { thema = props.thema }
    if (props.autor) { autor = props.autor }
    if (props.suchbegriff) { suchbegriff = props.suchbegriff }

    useEffect(() => {
        (async (link) => {
            let data = [];
            let res, json;
            do {
                res = await fetch(link);
                json = await res.json();
                data = data.concat(json.data);
                link = json.meta.next;
            } while (link);
            return data;
        })(`/api/index.php?limit=500&tags=${thema}&year=${String(zeit)}&author=${autor}&country=${land}&search=${suchbegriff}&type=${props.imEx == 'import' ? '1' : props.imEx == 'export' ? '2' : "3"}`)
            .then((data) => {
                props.setListData(data);
                setFullData(data)
            });

        if (props.imEx == "import") {
            (async (link) => {
                let data = [];
                let res, json;
                do {
                    res = await fetch(link);
                    json = await res.json();
                    data = data.concat(json.data);
                    link = json.meta.next;
                } while (link);
                return data;
            })(`/api/importe.php?limit=500&tags=${thema}&year=${String(zeit)}&author=${autor}&country=${land}&search=${suchbegriff}`)
                .then(data => setMapData(data.filter(set => set.lat)));
        }
        else {
            (async (link) => {
                let data = [];
                let res, json;
                do {
                    res = await fetch(link);
                    json = await res.json();
                    data = data.concat(json.data);
                    link = json.meta.next;
                } while (link);
                return data;
            })(`/api/exporte.php?limit=500&tags=${thema}&year=${String(zeit)}&author=${autor}&country=${land}&search=${suchbegriff}`)
                .then(data => setMapData(data.filter(set => set.lat)));
        }
    }, [])


    //const {loading, error, first, records} = useReadCypher(query, {land:props.param.land, autor:props.param.autor, thema:props.param.thema, zeit:props.param.zeit})

    /*if(loading) return <div>Loading...</div>
    if(error) return <div>{error.message}</div>*/

    //const result = records?.map(row=>{
    //const l1 = row.get('l1')
    //const l2 = row.get('l2')
    //return [l1.properties.coordinates[0], l1.properties.coordinates[1], l2.properties.coordinates[0], l2.properties.coordinates[1]]})

    return (
        <div>
            <Map data={mapData} imEx={props.imEx} onIDClick={(article) => props.onIDClick(article)} listData={fullData} land={props.land} />
        </div>
    )
}

export default Query;