import React from "react";
export default function AllgemeineAngabenSection(props) {
    return (
        <section>
            <h2 className="has-background-light is-size-6 has-text-weight-semibold has-text-centered mt-4">Allgemeine Angaben zum Artikel</h2>
            <h3 className="pl-5 pt-4 is-underlined">Textsorte</h3>
            <ul className="pl-5 pt-4">
                <li><b>Literaturwissenschaftliche Textsorte:</b> {props.litwisstextsorte}</li>
                <li><b>Sprachbefund:</b> {props.sprachbefund}</li>
                {props.uebersetzer ? <li><b>Übersetzer:</b> {props.uebersetzer}</li> : <></>}
                {props.rubrik ? <li><b>Rubrik:</b> {props.rubrik}</li> : null}
            </ul>
        </section>
    )
}