import { useEffect, useState } from "react";
import React from "react";
import AutoCompleteField from './AutoCompleteField';

/**
 * Functional component Form
 * 
 * Contains the form the user sees to input search terms and display data.
 * Data from the user is passed back to the parent component.
 * The component calls AutoCompleteFields als child components.
 * 
 * @param {*} props 
 * @returns 
 */
export default function Form(props) {

    /**
     * State Variables
     */
    const [anzeige, setAnzeige] = useState(""); // (String) either "import" or "export"
    const [_land, setLand] = useState(""); // (String) contains user entry in input field Land
    const [_thema, setThema] = useState(""); // (String) contains user entry in input field Thema
    const [_autor, setAutor] = useState(""); // (String) contains user entry in input field Autor
    const [_zeit, setZeit] = useState(""); // (String) contains user entry in input field Jahr
    const [_suchbegriff, setSuchbegriff] = useState(""); // (String) contains user entry in input field Suchbegriff
    const [landData, setLandData] = useState([""]); // (String) contains data for Autocomplete of field Land
    const [themaData, setThemaData] = useState([""]); // (String) contains data for Autocomplete of field Thema
    const [autorData, setAutorData] = useState([""]); // (String) contains data for Autocomplete of field Autor
    const [zeitData, setZeitData] = useState([""]); // (String) contains data for Autocomplete of field Zeit
    const [update, setUpdate] = useState(true); // (Boolean)  Determines whetcher data should be fetched from the database

    /**
     * useEffect: Load autocomplete data from database
     */
    useEffect(() => {

        if (update) { //Only fetch if update is true (once per session)

            // fetch data for Land field
            (async (link) => {
                let data = [];
                let res, json;
                do {
                    res = await fetch(link);
                    json = await res.json();
                    data = data.concat(json.data.map(country => country.name));
                    link = json.meta.next;
                } while (link);
                return data;
            })('/api/laender.php?limit=500').then(data => setLandData(data));

            // fetch data for Thema field
            (async (link) => {
                let data = [];
                let res, json;
                do {
                    res = await fetch(link);
                    json = await res.json();
                    data = data.concat(json.data);
                    link = json.meta.next;
                } while (link);
                return data;
            })('/api/realitaeten.php?limit=500').then(data => setThemaData(cleanRealitaeten(data)));

            // fetch data for Autor field
            (async (link) => {
                let data = [];
                let res, json;
                do {
                    res = await fetch(link);
                    json = await res.json();
                    data = data.concat(json.data);
                    link = json.meta.next;
                } while (link);
                return data;
            })('/api/autoren.php?limit=500').then(data => setAutorData(data));

            // fetch data for Jahr field
            (async (link) => {
                let data = [];
                let res, json;
                do {
                    res = await fetch(link);
                    json = await res.json();
                    data = data.concat(json.data);
                    link = json.meta.next;
                } while (link);
                return data;
            })('/api/zeiten.php?limit=500').then(data => setZeitData(data));

            setUpdate(false); // When all data is fetched, set update to false
        }
    })


    /**
     * Function cleanRealitaeten
     * 
     * Cleans the raw data from the database for the autocomplete function
     * of the field Thema.
     * The original data contains one string with comma separated words.
     * This string is split up and formed into a new array, that contains only unique values.
     * 
     * @param {Array} rawJson - JSON from the database
     * @returns data ready for diplay in autocomplete for field Thema
     */
    const cleanRealitaeten = (rawJson) => {
        return [...new Set(rawJson.map((r) => (r.split(', '))).flat(Infinity))] // Guarantees only unique values
    }

    const onImExValueChange = (event) => {
        props.handleImExChange(event.target.value);
        setAnzeige(event.target.value);
    }

    const onLandValueChange = (value) => {
        setLand(value);
        props.handleLandChange(value);
    }

    const onThemaValueChange = (value) => {
        setThema(value);
        props.handleThemaChange(value);
    }

    const onAutorValueChange = (value) => {
        setAutor(value);
        props.handleAutorChange(value);
    }

    const onZeitValueChange = (value) => {
        setZeit(value);
        props.handleZeitValueChange(value);
    }

    const onSuchbegriffValueChange = (value) => {
        setSuchbegriff(value);
        props.handleSuchbegriffChange(value);
    }

    const handleButtonClick = (event) => {
        props.submitQuery();
        props.displayList();
    }


    return (
        <div className="form pl-6 pr-6">
            <h2 className="has-text-centered has-background-light pl-6">Suchparameter</h2>
            <div className="columns has-text-centered">
                <div className="column pt-6 pb-6">
                    <div className="field pl-6">
                        <label className="label has-text-weight-semibold is-size-5">Land</label>
                        <div className="control">
                            <AutoCompleteField mode={"Land"} data={landData} onValueChange={(value) => onLandValueChange(value)} />
                        </div>
                    </div>
                    <div className="field pl-6">
                        <label className="label has-text-weight-semibold is-size-5">Thema</label>
                        <div className="control">
                            <AutoCompleteField mode={"Thema"} data={themaData} onValueChange={(value) => onThemaValueChange(value)} />
                        </div>
                    </div>
                    <div className="field pl-6">
                        <label className="label has-text-weight-semibold is-size-5">Autor</label>
                        <div className="control">
                            <AutoCompleteField mode={"Autor"} data={autorData} onValueChange={(value) => onAutorValueChange(value)} />
                        </div>
                    </div>
                </div>
                <div className="column pt-6 pb-6">
                    <div className="field pl-6">
                        <label className="label has-text-weight-semibold is-size-5">Zeit</label>
                        <div className="control">
                            <AutoCompleteField mode={"Jahr"} data={zeitData} onValueChange={(value) => onZeitValueChange(value)} />
                        </div>
                    </div>
                    <div className="field pl-6">
                        <label className="label has-text-weight-semibold is-size-5">Suchbegriff</label>
                        <div className="control">
                            <AutoCompleteField mode={"Suchbegriff"} data={[]} onValueChange={(value) => onSuchbegriffValueChange(value)} disable={true} />
                        </div>
                    </div>
                </div>
            </div>
            <h2 className="has-text-centered has-background-light pl-6">Import oder Export anzeigen</h2>
            <div className="field pl-6 pt-5 has-text-centered">
                <div className="control">
                    <label className="radio is-size-6">
                        <input type="radio" value="import" name='imOrEx' checked={anzeige === "import"} onChange={(event) => onImExValueChange(event)} />
                        <span className="pl-2">Kultur-Import</span>
                    </label>
                </div>
            </div>
            <div className="field pl-6 has-text-centered">
                <div className="control">
                    <label className="radio is-size-6">
                        <input type="radio" value="export" name='imOrEx' checked={anzeige === "export"} onChange={(event) => onImExValueChange(event)} />
                        <span className="pl-2">Kultur-Export</span>
                    </label>
                </div>
            </div>


            <div className="buttons pt-5">
                <button className="input is-info is-fullwidth" onClick={(event) => handleButtonClick(event)}>Anzeigen</button>
            </div>
        </div>
    )


}