import React from "react";

export default function InfoSection(props) {
    return (
        <>
            <p className="pl-5 pt-4">
                {props.autorvorname} {props.autornachname},
                <span className="is-italic">{props.artikeltitel}</span>,
                in: {props.ztitel}, {props.jahr}, Bd. {props.band}, H. {props.heft}, S. {props.seiteVon}-{props.seiteBis}.
            </p>
        </>
    )
}