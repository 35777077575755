import AllgemeineAngabenSection from "./AllgemeineAngabenSection"
import GrenzAspekteSection from "./GrenzAspekteSection"
import InfoSection from "./InfoSection"
import SchlagwoerterSection from "./SchlagwoerterSection"
import React from "react";

export default function Karteikarte(props) {

    const besonderheiten =
        <>
            <h2 className="has-background-light is-size-6 has-text-weight-semibold has-text-centered mt-4">Besonderheiten</h2>
            <p className="pl-5 pt-4">{props.data.Besonderheiten}</p>
        </>

    if (props.display) {
        return (
            <section id="Karteikarte">
                <h1 className="is-size-4 has-text-centered has-text-weight-bold pt-6">Karteikarte (ID {props.data.ArtID})</h1>
                <InfoSection autorvorname={props.data.Autorvorname}
                    autornachname={props.data.Autorname}
                    artikeltitel={props.data.Artikeltitel}
                    ztitel={props.data.ZTitel}
                    jahr={props.data.Jahr}
                    band={props.data.Band}
                    heft={props.data.Heft}
                    seiteVon={props.data.Seite_von}
                    seiteBis={props.data.Seite_bis} />
                <AllgemeineAngabenSection
                    litwisstextsorte={props.data.Litwisstextsorte}
                    sprachbefund={props.data.Sprachbefund}
                    rubrik={props.data.Rubrik}
                    uebersetzer={props.data.Uebersetzer}
                />
                <SchlagwoerterSection schlagwoerter={props.data.Schlagwoerter} realitaeten={props.data.Realitaetsbereiche} />
                <GrenzAspekteSection
                    interdisziplinaritaet={props.data.Interdisziplinaritaet}
                    intermedialitaet={props.data.IntermedialitaetInterarts}
                    internationalitaet={props.data.Internationalitaet}
                    kulturtransfer={props.data.Kulturtransfer}
                    fremdsprachigeWorte={props.data.FremdsprachigeWoerter}
                    importAus={props.data.Import_aus}
                    exportNach={props.data.Export_nach} />

                <h2 className="has-background-light is-size-6 has-text-weight-semibold has-text-centered mt-4">Resümee</h2>
                <p className="pl-5 pt-4">{props.data.Resuemee}</p>

                {props.Besonderheiten ? besonderheiten : <></>}

            </section>
        )
    }
    else {
        return (<></>)
    }
}