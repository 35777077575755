import React from "react";

export default function PaginationButton(props) {

    const onPaginationButtonClick = () => {
        props.onPaginationButtonClick(props.value);
    }

    if (props.isActive) {
        return (
            <li key={props.value}>
                <a className={"pagination-link is-current"}>{props.value}</a>
            </li>
        )
    } else {
        return (
            <li key={props.value}>
                <a className={"pagination-link"} onClick={onPaginationButtonClick}>{props.value}</a>
            </li>
        )
    }
}