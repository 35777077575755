import React, { useState } from 'react';
import '../index.css';
import InformationModal from './InformationModal';

export default function Navbar() {

    const [modalIsActive, setModalIsActive] = useState(false)

    const onInformationClick = () => {
        setModalIsActive(true);
    }

    const onModalCloseButtonClick = () => {
        setModalIsActive(false);
    }

    if (modalIsActive) {
        return (
            <InformationModal onModalCloseButtonClick={onModalCloseButtonClick} />
        )
    }
    return (
        <nav className="navbar is-warning" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
                <div className="navbar-item has-text-centered has-text-weight-semibold is-size-2">
                    KuVis
                </div>
            </div>
            <div id="mainNavbar" className="navbar-menu">
                <div className="navbar-start">
                </div>
                <div className="navbar-end">
                    <a className="navbar-item has-text-weight-semibold" href="jhw/">
                        Ursprüngliche Version
                    </a>
                    <a className="navbar-item has-text-weight-semibold" onClick={() => onInformationClick()}>
                        Informationen
                    </a>
                </div>
            </div>
        </nav>
    );
}