import React from "react";
import L from 'leaflet';
import 'leaflet-swoopy';
import { Component } from "react";

const dims = {
  width: "100%",
  height: "600px"
};

const origin_names = {
  MF: "Frankreich",
  NA: "Italien",
  DR: "Deutschland",
  FRB: "Deutschland",
  WM: "Deutschland",
};

const origin_coords = {
  MF: [46.227638, 2.213749],
  NA: [41.87194, 12.56738],
  DR: [51.165691, 10.451526],
  FRB: [51.165691, 10.451526],
  WM: [51.165691, 10.451526],
};

let arrowLayer = {};
let circleLayer = {};

class Map extends Component {

  constructor(props) {
    super(props);
    this.state = {
      arrows: [],
      filtered_arrows: [],
      circles: [],
      filtered_circles: [],
      showAllCountries: true,
    };
  }

  componentDidMount() {
    this.renderMap();
    this.makeArrows();
    this.makeCircles();
  }

  componentDidUpdate(prevprops) {
    if (this.props.data[0] != prevprops.data[0]) {
      this.makeArrows();
      this.makeCircles();
    }
  }

  makeArrows() {
    let arrowArray;
    if (this.props.imEx == 'import') {
      arrowArray = this.props.data.map((row) => ({ from: [row.lat, row.lon], to: origin_coords[row.ZCode?.toUpperCase()] }))
    } else {
      arrowArray = this.props.data.map((row) => ({ from: origin_coords[row.ZCode?.toUpperCase()], to: [row.lat, row.lon] }))
    }
    let jsonArrows = [... new Set(arrowArray.map(arrow => JSON.stringify(arrow)))]
    arrowArray = jsonArrows.map(arrow => JSON.parse(arrow))
    this.setState({ arrows: arrowArray }, () => this.renderArrows())
  }

  renderArrows() {

    try { this.map.removeLayer(arrowLayer); } catch (e) { console.log(e) }
    try {
      let swoopies = this.state.arrows.map(arrow => L.swoopyArrow(arrow.from, arrow.to, {
        weight: 2,
        color: 'green',
      }));
      arrowLayer = L.layerGroup(swoopies);
      arrowLayer.addTo(this.map);
    } catch (e) {
      console.log(e);
    }
  }

  makeCircles() {
    let circleArray = this.props.data.map((row) => ({ coords: [row.lat, row.lon] }))
    let uniqueCircles = [... new Set(circleArray.map(circle => JSON.stringify(circle)))].map((circle => JSON.parse(circle)));
    this.props.data.map(row => {
      for (let i = 0; i < uniqueCircles.length; i++) {
        if (row.lat == uniqueCircles[i].coords[0] && row.lon == uniqueCircles[i].coords[1]) {
          if (uniqueCircles[i].data) {
            uniqueCircles[i].data.push(row);
          }
          else {
            uniqueCircles[i].data = [row];
          }
          break;
        }
      }
    })
    this.setState({ circles: uniqueCircles }, () => this.renderCircles());
  }

  renderCircles() {
    try { this.map.removeLayer(circleLayer); } catch (e) { console.log(e) }
    try {
      let bloopies = this.state.circles.map(circle =>
        L.circle(circle.coords, {
          color: 'green',
          fillOpacity: 1,
          radius: 50000,
        }).bindPopup(L.popup().setContent(this.createPopupContent(circle.data, this.props.imEx)),
          { 'maxWidth': '500', 'maxHeight': '350', 'minWidth': '350' }));
      // console.log(bloopies)
      circleLayer = L.layerGroup(bloopies);
      circleLayer.addTo(this.map);
    } catch (e) { console.log(e) }
  }

  createPopupContent(data, imEx) {
    const popupcontent = L.DomUtil.create('div');
    let country;
    data.map((row) => {
      country = imEx == 'import' ? row.Import_aus : row.Export_nach
      const p = L.DomUtil.create('p');
      const a = L.DomUtil.create('a');
      a.textContent = row.ArtID;
      a.href = '#Karteikarte';
      a.onclick = () => this.props.onIDClick(this.props.listData.filter((article) => article.ArtID == row.ArtID)[0]);
      if (imEx == 'import') {
        p.textContent = p.textContent = `: ${row.Autorvorname} ${row.Autorname}, ${row.Artikeltitel}, in: ${row.ZCode}, ${row.Jahr} nach: ${origin_names[row.ZCode]}`;
      }
      else {
        p.textContent = `: ${row.Autorvorname} ${row.Autorname}, ${row.Artikeltitel}, in: ${row.ZCode}, ${row.Jahr} aus: ${origin_names[row.ZCode]}`
      }
      p.insertBefore(a, p.firstChild);
      popupcontent.appendChild(p)
    })
    const title = L.DomUtil.create('b');
    if (imEx == 'import') {
      title.textContent = `Import aus ${country}`;
    } else {
      title.textContent = `Export nach ${country}`;
    }
    popupcontent.insertBefore(title, popupcontent.firstChild);
    return popupcontent;
  }

  renderMap() {
    this.map = L.map("map", {
      center: [54.9, 25.316667],
      zoom: 2,
      layers: [
        L.tileLayer("https://{s}.tile.osm.org/{z}/{x}/{y}.png", { attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors' })
      ]
    });
  }

  render() {
    return <>
      <div id="map" style={dims}></div>
    </>;
  }

}

export default Map;