import { useEffect, useState } from "react"
import React from "react";
import PaginationMenu from "./PaginationMenu"

export default function List(props) {

    const [activePage, setActivePage] = useState(props.activePage)
    const [entriesPerPage, _setEntriesPerPage] = useState(10)

    const onPaginationButtonClick = (value) => {
        setActivePage(value);
        props.onPaginationButtonClick(value)
    }

    const setKarteikartenData = (value) => {
        props.setKarteikartenData(value);
    }

    if (props.display) {

        const data = props.data
        let numberOfPages = Math.ceil(data.length / entriesPerPage)

        return (
            <>
                <div className="table-container has-text-centered pt-6">
                    <table className="table is-bordered mx-auto mb-2">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Bibliographische Notiz</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.slice(activePage * entriesPerPage - entriesPerPage, (activePage + 1) * entriesPerPage - entriesPerPage).map(article => (
                                <tr key={article.ArtID}>
                                    <td>{article.ArtID}</td>
                                    <td className="has-text-left">{article.Autorvorname} {article.Autorname}: <span className="is-italic">{article.Artikeltitel}</span>, {article.ZCode}, {article.Jahr}, M. {article.Monat}, Bd. {article.Band}, H. {article.Heft}, S. {article.Seite_von}-{article.Seite_bis}.</td>
                                    <td><a onClick={() => setKarteikartenData(article)}>Karteikarte</a></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <PaginationMenu activePage={activePage} numberOfPages={numberOfPages} onPaginationButtonClick={(value) => onPaginationButtonClick(value)} />
            </>
        )
    }
    else {
        return (<p></p>)
    }


}