import React from "react";

export default function SchlagwoerterSection(props) {

    let p_realitaeten =
        <>
            <h3 className="pl-5 pt-4 is-underlined">Realitäten</h3>
            <p className="pl-5 pt-4">
                {props.realitaeten}
            </p>
        </>;

    let p_schlagwoerter =
        <div>
            <h3 className="pl-5 pt-4 is-underlined">Schlagwörter</h3>
            <p className="pl-5 pt-4">
                {props.schlagwoerter}
            </p>
        </div>;

    return (
        <>
            {props.schlagwoerter ? p_schlagwoerter : <></>}
            {props.realitaeten ? p_realitaeten : <></>}
        </>
    )
}