import React from 'react';
import Navbar from './Navbar';
import Content from './Content';

/**
 * Functional Component App
 * 
 * The basic frame of the app, rendering the components Navbar and Content
 * 
 */
function App() {
    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div>
                <Content />
            </div>
        </div>
    )
}

export default App;