import React from "react";
import PaginationButton from "./PaginationButton";

export default function PaginationMenu(props) {

    const onPaginationButtonClick = (value) => {
        props.onPaginationButtonClick(value);
    }

    const onBackButtonClick = () => {
        if (props.activePage > 1) {
            props.onPaginationButtonClick(props.activePage - 1);
        }
    }

    const onNextButtonClick = () => {
        if (props.activePage < props.numberOfPages) {
            props.onPaginationButtonClick(props.activePage + 1);
        }
    }

    const paginationButton = (index) => {
        return <PaginationButton key={index} value={index} isActive={index == props.activePage ? true : false} onPaginationButtonClick={onPaginationButtonClick} />
    }

    const numberOfPages = props.numberOfPages;

    let buttons = [];
    for (let i = 1; i <= numberOfPages; i++) {
        buttons[i] = i;
    }

    let pageButtons = (<></>)

    if (props.numberOfPages < 6) {
        pageButtons = (
            <ul className="pagination-list">
                {buttons.map((button) => paginationButton(button))}
            </ul>
        )
    } else {
        pageButtons = (
            <ul className="pagination-list">
                {props.activePage > 2 ? paginationButton(1) : (<></>)}
                {props.activePage > 3 ? <li key={"el1"}><span className="pagination-ellipsis">&hellip;</span></li> : (<></>)}
                {props.activePage > 1 ? paginationButton(props.activePage - 1) : (<></>)}
                {paginationButton(props.activePage)}
                {props.activePage < props.numberOfPages - 1 ? paginationButton(props.activePage + 1) : (<></>)}
                {props.activePage < props.numberOfPages - 3 ? <li key={"el2"}><span className="pagination-ellipsis">&hellip;</span></li> : (<></>)}
                {props.activePage < props.numberOfPages - 2 ? paginationButton(props.numberOfPages - 1) : (<></>)}
            </ul>
        )
    }

    return (
        <>
            <nav className="pagination is-centered">
                <a className="pagination-previous" onClick={onBackButtonClick}>Zurück</a>
                {pageButtons}
                <a className="pagination-next" onClick={onNextButtonClick}>Weiter</a>
            </nav>
        </>
    )
}