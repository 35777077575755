import { useState } from "react"
import React from "react";
export default function InformationModal(props) {

    const [activeTab, setActiveTab] = useState(0)
    const modalData = require('../data/informationen.json')


    return (
        <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title has-text-centered">Informationen</p>
                </header>
                <section className="modal-card-body">
                    <div className="tabs is-boxed">
                        <ul>
                            <li className={activeTab == 0 ? "is-active" : ""} onClick={() => setActiveTab(0)}><a>{modalData[0].name}</a></li>
                            <li className={activeTab == 1 ? "is-active" : ""} onClick={() => setActiveTab(1)}><a>{modalData[1].name}</a></li>
                            <li className={activeTab == 2 ? "is-active" : ""} onClick={() => setActiveTab(2)}><a>{modalData[2].name}</a></li>
                            <li className={activeTab == 3 ? "is-active" : ""} onClick={() => setActiveTab(3)}><a>{modalData[3].name}</a></li>
                            <li className={activeTab == 4 ? "is-active" : ""} onClick={() => setActiveTab(4)}><a>{modalData[4].name}</a></li>
                            {/* <li className={activeTab == 5 ? "is-active" : ""} onClick={() => setActiveTab(5)}><a>{modalData[5].name}</a></li> */}
                        </ul>
                    </div>
                    <div className="content" dangerouslySetInnerHTML={{ __html: modalData[activeTab].text }}>

                    </div>
                </section>
                <footer className="modal-card-foot">
                    <button className="button" onClick={() => props.onModalCloseButtonClick()}>Schließen</button>
                </footer>
            </div>
        </div>
    )
}