import React from "react";
import { useState, useEffect } from "react"

/**
 * Functional component AutoCompleteField
 * 
 * Generates an input field that uses data from props.data to display
 * Autocomplete suggestions.
 * @param {Array} props.data  - contains the data to be displayed
 * @param {String} props.mode - specifies the usage of the input field and the field "Jahr" has to be treated seperately.
 */
export default function AutoCompleteField(props) {

    /**
     * State Variables
     * 
     * filteredData (Array) - contains data from props.data after being filtered
     * fieldContent (String) - contains whatever is written in the input field by the user
     * activeToggle (Boolean) - Toggle to determine whether the suggestions are visible or not
     */
    const [filteredData, setFilteredData] = useState([""])
    const [fieldContent, setFieldContent] = useState("")
    const [activeToggle, setActiveToogle] = useState(false)

    /**
     * UseEffect
     * 
     * triggered when the fieldContent (=> State) changes. 
     * Data is re-filtered according to current field entry and 
     * the dropdown menu is hidden by setting activeToggle (state) to false if nothing is written in the field
     */
    useEffect(() => {
        filterListData();
        if (fieldContent == 0) {
            setActiveToogle(false);
        }
    }, [fieldContent]);

    /**
     * Function filterListData
     * 
     * Filters props.data and returns all elements that include fieldContent (=> State)
     */
    const filterListData = () => {
        setFilteredData(props.data.filter(word => word.includes(fieldContent)).sort())
    }

    /**
     * Function onContentChange
     * 
     * Handles change in the input field by user by setting this component's state accordingly 
     * and also passing the state up to the parent component
     * 
     * @param {Object} event - The event from the user input
     */
    const onContentChange = (event) => {
        if (!props.disable) {
            setActiveToogle(true);
        }
        //Input from "Jahr" has to be parsed as int
        setFieldContent(props.mode === "Jahr" && event.target.value ? parseInt(event.target.value) : event.target.value);
        props.onValueChange(event.target.value);

    }

    /**
     * Function onDropdownItemClick
     * 
     * Handles click on dropdown Item by user by setting activeToggle (=> State) to false,
     * Setting fieldContent (=> State) accordingly
     * and passing it up to the parent component
     * 
     * @param {String} datapoint - the value chosen by the user
     */
    const onDropdownItemClick = (datapoint) => {
        setActiveToogle(false)
        setFieldContent(props.mode === "Jahr" && datapoint ? parseInt(datapoint) : datapoint)
        props.onValueChange(datapoint)
    }

    /**
     * Return input field with toggable dropdown menu containing filtered data
     */
    return (
        <div className={`dropdown ${activeToggle ? "is-active" : ""}`}>
            <div className="dropdown-trigger" >
                <input className="input" value={fieldContent} type="text" placeholder={`${props.mode} eingeben`} onInput={(event) => onContentChange(event)}></input>
            </div>
            <div className="dropdown-menu">
                <div className="dropdown-content has-text-left">
                    {filteredData.map((datapoint) => (<a key={datapoint} className="dropdown-item" onClick={() => onDropdownItemClick(datapoint)}>{datapoint}</a>))}
                </div>
            </div>
        </div>
    )
}