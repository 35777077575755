import React, { Component } from 'react';
import Form from './Form';
import Query from './Query';
import List from './List';
import Karteikarte from './Karteikarte';

/**
 * Class Component Content
 * 
 * The basic Component serving as a container for the website. 
 * It renders all other components like the map, the form and the result list
 * ans also controls information flow along the Application
 */
class Content extends Component {

    /**
     * @constructor Constructor for the component
     * @param {*} props - empty, no props required
     */
    constructor(props) {
        super(props)
        /**
         * State Variables
         * 
         * anzeige (String) - "import" or "export", determined by user's choice in the interface
         * land (String) - stores user entry from input field "Land"
         * thema (String) - stores user entry from input field "Thema"
         * autor (String) - stores user entry from input field "Autor"
         * suchbegriff (String) - stores user entry from input field "Suchbegriff"
         * zeit (Integer) - stores user entry from input field "Zeit"
         * update (Boolean) - flag determining whether the map updates to show new data
         * listData (Array) - contains data that should be displayed in the list
         * displayList (Boolean) - determines whether or not the list is displayed 
         * karteikartenData (Array) - contains data to display in the Karteikarte
         * displayKarteikarte (Boolean) - determines whether ot not a Karteikarte should be displayed
         */
        this.state = {
            anzeige: '',
            land: '',
            thema: '',
            autor: '',
            suchbegriff: '',
            zeit: null,
            update: true,
            listData: [],
            displayList: false,
            karteikartenData: {},
            displayKarteikarte: false,
            activepage: 1,
            updateList: false
        }

        /**
         * Binding class functions to this so that they can be given as props to other components
         */
        this.handleImExChange = this.handleImExChange.bind(this)
        this.handleLandChange = this.handleLandChange.bind(this)
        this.handleAutorChange = this.handleAutorChange.bind(this)
        this.handleThemaChange = this.handleThemaChange.bind(this)
        this.handleZeitChange = this.handleZeitChange.bind(this)
        this.submitQuery = this.submitQuery.bind(this)
        this.handleSuchbegriffChange = this.handleSuchbegriffChange.bind(this)
        this.setKarteikartenData = this.setKarteikartenData.bind(this)

    }


    /**
     * Function handleImExChange
     * 
     * Handler for change of Import/Export Radio Button, 
     * sets anzeige (=> State) accordingly
     * 
     * @param {String} imEx - value from radio button
     */
    handleImExChange = (imEx) => {
        this.setState({ anzeige: imEx })
    }

    /**
     * Function handleLandChange
     * 
     * Handler for change of Land input field, 
     * sets land (=> State) accordingly
     * 
     * @param {String} land - value from event.target.value of input field
     */
    handleLandChange = (land) => {
        this.setState({ land: land })
    }

    /**
     * Function handleThemaChange
     * 
     * Handler for change of Thema input field,
     * sets thema (=> State) accordingly
     * @param {String} thema - value from event.target.value of input field
     */
    handleThemaChange = (thema) => {
        this.setState({ thema: thema })
    }

    /**
     * Function handleAutorChange
     * 
     * Handler for change of Autor input field,
     * sets autor (=> State) accordingly
     * @param {String} autor - value from event.target.value of input field
     */
    handleAutorChange = (autor) => {
        this.setState({ autor: autor })
    }

    /**
     * Function handleZeitChange
     * 
     * Handler for change of Jahr input field,
     * parses value to int and sets zeit (=> State) accordingly.
     * @param {String} zeit - value from event.target.value of input field
     */
    handleZeitChange = (zeit) => {
        this.setState({ zeit: parseInt(zeit) })
    }

    /**
     * Function handleSuchbegriffChange
     * 
     * Handler for change of Suchbegriff input field,
     * sets suchbegriff (=> State) accordingly
     * @param {String} suchbegriff - value from event.target.value of input field
     */
    handleSuchbegriffChange = (suchbegriff) => {
        this.setState({ suchbegriff: suchbegriff })
    }

    /**
     * Function displayList
     * 
     * Toggles displayList (=> State) to true, so the list is displayed
     */
    displayList = () => {
        this.setState({ displayList: true })
    }

    /**
     * Function afterListDisplay
     * 
     * Can be called after the list has been displayed to set displayList (=> State) to false
     */
    afterListDisplay = () => {
        this.setState({ displayList: false })
    }

    /**
     * Function setListData
     * 
     * Stores data in listData (=> State)
     * @param {Array} data 
     */
    setListData = (data) => {
        this.setState({ listData: data, activepage: 1 })
    }

    /**
     * Function setKarteikartenData
     * 
     * Stores data for the Karteikarte in karteikartenData (=> State)
     * and toggles display of Karteikarte by setting displayKarteikarte (=> State) to true
     * @param {Array} data 
     */
    setKarteikartenData = (data) => {
        this.setState({ karteikartenData: data })
        this.setState({ displayKarteikarte: true })
    }

    /**
     * Function afterKarteikarteDisplay
     * 
     * Can be called after the Karteikarte is displayed to set displayKarteikarte to false
     */
    afterKarteikarteDisplay = () => {
        this.setState({ displayKarteikarte: false })
    }

    /**
     * Function submitQuery
     * 
     * Can be called on submit click by user and will cause the application to reload.
     */
    submitQuery = () => {
        this.setState({ update: !this.state.update })
    }

    onPaginationButtonClick = (page) => {
        this.setState({ activepage: page })
    }



    render() {
        return (
            <>
                <p className='subtitle has-text-centered pt-5 pb-0'>Visualisierung auf der Karte</p>
                <div className="columns">
                    <div className="column pt-6">
                        <Form
                            handleImExChange={this.handleImExChange}
                            handleLandChange={this.handleLandChange}
                            handleThemaChange={this.handleThemaChange}
                            handleAutorChange={this.handleAutorChange}
                            handleZeitValueChange={this.handleZeitChange}
                            handleSuchbegriffChange={this.handleSuchbegriffChange}
                            submitQuery={this.submitQuery}
                            displayList={this.displayList} />

                    </div>
                    <div className="column pr-6 pt-6">
                        <Query key={this.state.update} setListData={this.setListData} land={this.state.land} autor={this.state.autor} thema={this.state.thema} zeit={this.state.zeit} suchbegriff={this.state.suchbegriff} imEx={this.state.anzeige} onIDClick={this.setKarteikartenData} />
                    </div>
                </div>
                <p className='subtitle has-text-centered pt-6'>Datensätze anzeigen</p>
                <p className='has-text-centered'>Hinweis: Das Feld "Suchbegriff" beeinflusst nur die Datensätze der hier angezeigten Tabelle, nicht die Anzeige auf der Karte.</p>
                <div className='columns'>
                    <div className='column'>
                        <List display={this.state.displayList} data={this.state.listData} afterListDisplay={this.afterListDisplay} setKarteikartenData={this.setKarteikartenData} activePage={this.state.activepage} onPaginationButtonClick={(page) => this.onPaginationButtonClick(page)} update={this.state.update} />
                    </div>
                    <div className='column'>
                        <Karteikarte display={this.state.displayKarteikarte} data={this.state.karteikartenData} afterKarteikarteDisplay={this.afterKarteikarteDisplay} />
                    </div>
                </div>
            </>
        );
    }

}

export default Content